import type { ProductType, ProductTypeRow } from '@openesg/supabase/models'
import {
  fetchOwnedProduct,
  fetchProductImage,
  moveImageOnCreate,
  removeProductImage,
  updateOwnedProduct,
  uploadProductImage,
} from '~~/services/ProductServices'

import { CompanyTypeRow } from '~~/server/types'

export const useProductStore = defineStore('product', () => {
  const product = ref<ProductTypeRow | null>(null)
  const {
    uploadPending,
    uploadedPath,
    uploadFile,
    removeFile,
    getFileUrl,
    moveFile,
  } = useFileUpload()

  const pending = ref(false)

  const fetch = async () => {
    pending.value = true
    try {
      const response = await fetchOwnedProduct()
      product.value = response.data
      return response
    } finally {
      pending.value = false
    }
  }

  const update = async (value: ProductType['Insert']) => {
    const response = await updateOwnedProduct({ ...value })
    if (response.data) {
      product.value = response.data
      if (!value.id && uploadedPath.value) {
        await moveFile(
          () =>
            moveImageOnCreate(
              response.data!.company_id,
              response.data!.id,
              uploadedPath.value!
            ),
          async (path: string) => update({ ...product.value!, image_url: path })
        )
      }
    }

    return response
  }

  const uploadImage = async (companyId: CompanyTypeRow['id'], image: File) => {
    return uploadFile(
      () => uploadProductImage(companyId, product.value?.id, image),
      product.value
        ? (path: string) => update({ ...product.value!, image_url: path })
        : undefined
    )
  }

  const currentProductUrl = computed(
    () => uploadedPath.value || product.value?.image_url
  )

  const getProductImage = () =>
    getFileUrl(currentProductUrl?.value, fetchProductImage)

  const removeImage = async () => {
    if (currentProductUrl.value)
      return removeFile(
        () => removeProductImage(currentProductUrl.value!),
        () => update({ ...product.value!, image_url: null })
      )
  }
  const hasSetupProduct = computed(() => !!product.value?.name)

  function $reset() {
    product.value = null
  }

  return {
    product,
    pending,
    fetch,
    update,
    $reset,
    uploadImage,
    removeImage,
    getProductImage,
    hasSetupProduct,
    uploadPending,
  }
})
